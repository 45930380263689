import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Containers from "../components/container"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import blogStyles from "../styles/blog.module.css"
import SEO from "../components/seo"


export default function Blog({ data }) {
  return (
        <Containers>
        <Header />
        <SEO title="บทความแบ่งปันความรู้ การทำเว็บไซต์ของเราโอนิจิท็อป" description="บทความแบ่งปันความรู้ ของเราโอนิจิท็อป แบ่งปันให้ความรู้ในการทำเว็บไซต์อย่างมืออาชีพ เพื่อให้คุณได้รู้หรืออัพเดทเทคโนโลยีการทำเว็บไซต์ก่อนใคร" />
           <Container fluid="xl" className={blogStyles.blogcontainer}>
               <Row>
                   <Col><center>
                   <h1>บทความ</h1>
                   <p>{data.allMarkdownRemark.totalCount} Post</p></center>
                   </Col>
               </Row>
               <Row>
                   {data.allMarkdownRemark.edges.map(({ node }) => (
                   <Col md={4} key={node.id}>
                   <a href={node.fields.slug}>
                   <div className={blogStyles.blogcol}>
                   <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
                      <div className={blogStyles.blogp}>
                        <p className={blogStyles.date}>{node.frontmatter.date}</p>
                        <h3 className={blogStyles.h3blog}>{node.frontmatter.title}{" "}</h3>
                        <p>{node.frontmatter.description}</p>
                      </div>
                   </div>
                   </a>
                   </Col>))}
               </Row>
               <Row className={blogStyles.rowlink}>
                 <Col><p>หน้า <Link className={blogStyles.pagenumber} to="#">1</Link>  »</p></Col>
               </Row>
           </Container>
        <Footer />
        </Containers>
    );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`